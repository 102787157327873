import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import sideBar from '../../img/sidebar.png';
import classes from './Navigation.module.css';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";
import { motion } from 'framer-motion/dist/framer-motion';

class Navigation extends Component {
    state = {
        displayNav: false,
    }

    sideBarHandler = () => {
        const displayNav = !(this.state.displayNav);
        this.setState({ displayNav: displayNav })
    }
    removeSidebarhandler = () => {
        this.setState({ displayNav: false })
    }
    render() {
        let style;
        if (this.state.displayNav) {
            style = { zIndex: 600 };
        }
        const { t } = this.props;
        const variants = {
            hidden:{display:'none', x:50},
            visible:{display:'block', x:0}
        }
        return (
            <div className={classes.mainContainer} style={style}>
                <div className={classes.languageContainer}>
                    <ul>
                        <li><a href="#" onClick={() => i18n.changeLanguage('en')}>EN</a></li>
                        <li><a href="#" onClick={() => i18n.changeLanguage('ar')}>عربي</a></li>
                    </ul>

                </div>
                <div className={classes.Navigation}>
                    <img src={sideBar} alt='sidebar' onClick={this.sideBarHandler} />
                    <motion.nav
                        animate={this.state.displayNav ? 'visible' : 'hidden'}
                        variants={variants}
                    >
                        <ul>
                            <Link to='/'>
                                <li onClick={this.removeSidebarhandler}>{t('nav1')}</li>
                            </Link>
                            <Link to='/projects'>
                                <li onClick={this.removeSidebarhandler}>{t('nav2')}</li>
                            </Link>
                        </ul>
                    </motion.nav>
                </div>

            </div>

        )
    }
}

export default withTranslation()(Navigation);