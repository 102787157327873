import React from 'react';
import classes from './Project.module.css';
import close from '../../../img/close.png';
import uniqid from 'uniqid';
import { withTranslation } from 'react-i18next';
import { motion } from 'framer-motion/dist/framer-motion';

const project = (props) => {
    const { t } = props;
    return (
        <motion.div
         className={classes.Project}
         initial={{scale:1.1}}
         animate={{scale:1}}
         >
            <div className={classes.imgContainer}>
                <img alt={props.title} src={props.img} />
            </div>
            <div className={classes.projectDetailsContainer}>
                <h3>{props.title}</h3>
                <ul>
                    {props.technologies.map(technology => <li key={uniqid()}>{technology}</li>)}
                </ul>
                <h4>{t('project1')}</h4>
                <p>{props.about}</p>
                <motion.a
                    href={props.websiteURL}
                    target="_blank"
                    whileHover={{ scale: 1.1 }}
                >
                    {t('project2')}
                </motion.a>
                <motion.a
                    href={props.codeURL}
                    target="_blank"
                    whileHover={{scale:1.1}}
                    >
                    {t('project3')}
                </motion.a>
            </div>
            <img alt='close' className={classes.CloseBtn} src={close} onClick={props.clicked} />
        </motion.div>

    )
}

export default withTranslation()(project);