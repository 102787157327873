import './App.css';
import Title from './components/Title/Title';
import Links from './components/Links/Links';
import Projects from './components/Projects/Projects';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Component } from 'react';
import Navigation from './components/Navigation/Navigation';
import Particles from './components/particles/particles';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Links />
        <Navigation />
        <Switch>
          <Route path="/projects" component={Projects} />
          <Route path="/" component={Title} />
        </Switch>
        <Particles />
      </BrowserRouter>


    )
  }
}
export default App;
