import React from 'react';
import classes from './Title.module.css';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { motion } from 'framer-motion/dist/framer-motion';

const Title = (props) => {
    const { t } = props;
    const variants = {
        hidden: { opacity: 0, left: '30px' },
        visible: { opacity: 1, left: '0' }
    }
    return (
        <motion.div
            className={classes.TitleContainer}
            style={{ overflowY: 'hidden' }}
            variants={variants}
            initial='hidden'
            animate='visible'
        >
            <div className={classes.Title}>
                <h1>{t('title1')}</h1>
                <h2>{t('title2')}</h2>
                <Link to='/projects'>
                    <motion.button
                        whileHover={{ scale: 1.2 }}
                        whileTap={{ scale: 0.8 }}
                    >
                        {t('title3')}
                    </motion.button>
                </Link>
            </div>
            <footer>
                <p><span>Email: </span>mustafa-abdishakur@hotmail.com</p>
            </footer>
        </motion.div>
    )
};

export default withTranslation()(Title);