import React from 'react';
import classes from './Links.module.css';
import linkedInMark from '../../img/linkedInMark.png';
import githubMark from '../../img/githubMark.png';
import emailMark from '../../img/email-icon.png';
const Links = () => (
    <div className={classes.Links}>
            <a href="https://www.linkedin.com/in/mustafa-abdishakur-41a88b1a1" target="_blank" className={[classes.Link, classes.LinkedIn].join(' ')}>
                <span>linkedIn</span>
                <img src={linkedInMark} />
            </a>
            <a href="https://github.com/Mustafa-Abdishakur" target="_blank" className={[classes.Link, classes.Github].join(' ')}>
                <span>Github</span>
                <img src={githubMark} />
            </a>
            <a href="mailto:mustafa-abdishakur@hotmail.com" target="_blank" className={[classes.Link, classes.Email].join(' ')}>
                <span>Email</span>
                <img src={emailMark} />
            </a>
    </div>
)

export default Links;