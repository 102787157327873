import React, { Component } from 'react';
import CovidImg from '../../img/covid-img.PNG';
import weatherImg from '../../img/weather.PNG';
import ecommerceImg from '../../img/ecommerce.PNG';
import classes from './Projects.module.css';
import Project from './Project/Project';
import chatAppImg from '../../img/chat-app.png';
import sanityImg from '../../img/sanity.png';
import tropicalWebsiteImg from '../../img/tropical-website.png';
import { withGetScreen } from 'react-getscreen';
import { withTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';

class Projects extends Component {
    state = {
        displayProject: false,
        project: null,
        disableBtn: false,
    }

    displayProjectHandler = (project) => {
        this.setState({ displayProject: true, project: project, disableBtn: true });
        if (this.props.isMobile()) {
            document.getElementById("Projects").scrollIntoView();
        }
    }
    closeProjectHandler = () => {
        this.setState({ displayProject: false, disableBtn: false })
    }
    render() {
        const { t } = this.props;
        const projects = [/* {
            title: t('projectDetails9'),
            img: tropicalWebsiteImg,
            about: t('projectDetails10'),
            technologies: ['HTML', 'CSS3', 'Responsive layout'],
            codeURL: '#',
            websiteURL: 'https://www.tropicalenergy.ae/'
        }, */
           /*  {
            title: t('projectDetails1'),
            img: CovidImg,
            about: t('projectDetails2'),
            technologies: ['HTML', 'CSS3', 'Javascript', 'Chart.js'],
            codeURL: 'https://github.com/Mustafa-Abdishakur/covid-tracker',
            websiteURL: 'https://c0vid-l9.netlify.app/'
        }, */
        {
            title: t('text1a'),
            img: sanityImg,
            about: t('text2a'),
            technologies: ['HTML', 'CSS3', 'Javascript', 'React.js', 'Sanity.io', 'Firebase', 'Stripe'],
            codeURL: 'https://github.com/Mustafa-Abdishakur/sanity-ecommerce',
            websiteURL: 'https://kkp-store.netlify.app/'
        }, 
        {
            title: t('projectDetails3'),
            img: chatAppImg,
            about: t('projectDetails4'),
            technologies: ['HTML', 'CSS3', 'Javascript', 'React.js (React hooks)', 'Firebase'],
            codeURL: 'https://github.com/Mustafa-Abdishakur/new-chat-app',
            websiteURL: 'https://chat-app12.netlify.app'
        },
        {
            title: t('projectDetails5'),
            img: weatherImg,
            about: t('projectDetails6'),
            technologies: ['HTML', 'Javascript', 'SASS', 'webpack', 'babel'],
            codeURL: 'https://github.com/Mustafa-Abdishakur/weather-app',
            websiteURL: 'https://weather-app2.netlify.app/'
        }];
        const variants = {
            hidden: { opacity: 0, left: '30px' },
            visible: { opacity: 1, left: '0' }
        }
        return (
            <motion.div
                className={classes.Projects}
                id="Projects"
                variants={variants}
                initial='hidden'
                animate='visible'
            >
                <div className={classes.projectsContent}>
                    <p>{t('projects1')}</p>
                    <p>// {t('projects2')}</p>
                    <motion.div
                        className={classes.ProjectsContainer}
                        layout
                    >
                        {
                            projects.map(project => {
                                return (
                                    <motion.div
                                        key={project.title}
                                        whileHover={{ scale: 1.05 }}
                                        layout
                                    >
                                        <motion.img
                                            src={project.img}
                                        />
                                        <motion.a
                                            href={"#" + classes.Projects}
                                            onClick={() => this.displayProjectHandler(project)}
                                            disabled={this.state.disableBtn}
                                            whileHover={{ scale: 1.05 }}
                                        >
                                            {t('projects3')}
                                        </motion.a>
                                    </motion.div>
                                )
                            })
                        }
                    </motion.div>
                </div>
                <AnimatePresence>
                    {this.state.displayProject && <Project {...this.state.project} clicked={this.closeProjectHandler} />}
                </AnimatePresence>
                <footer className={classes.footer}>
                    <p><span>Email: </span>mustafa-abdishakur@hotmail.com</p>
                </footer>
            </motion.div>
        )
    }

}

export default withTranslation()(withGetScreen(Projects));