import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'nav1': 'Home',
      'nav2': 'Projects',
      'title1': 'Hi, I am Mustafa Abdishakur',
      'title2': 'I am a web developer',
      'title3': 'View projects',
      'projects1': 'Portfolio',
      'projects2': 'These are some of my projects',
      'projects3': 'View Project',
      'project1': 'about',
      'project2': 'View demo',
      'project3': 'View code',
      'projectDetails1': 'Covid-19 tracker',
      'projectDetails2': 'A tracking application that displays the latest data of Covid-19 cases, recoveries and deaths of any country.',
      'projectDetails3':'Desktop chat app',
      'projectDetails4':'A chat application that can be used to chat with people in private or in groups.',
      'projectDetails5':'Weather App',
      'projectDetails6':'A Weather application that uses an API to display the weather of any city and the forecast of it.',
      'projectDetails7':'Glasses ecommerce website',
      'projectDetails8':'A demo ecommerce website specializing in selling a variety of eye glasses.',
      'projectDetails9':'The international society on tropical energy',
      'projectDetails10':'A website made for a client',
      'contact1':'Your message was sent. I will reply to you through email soon',
      'contact2':'Return to form',
      'contact3':'Do you have any inquires?',
      'contact4':'Name',
      'contact5':'Email',
      'contact6':'Message',
      'contact7':'Submit',
      'text1a': 'Ecommerce Website',
      'text2a': 'A fully functioning ecommerce website for selling heaphones with user authentication and payment gateway.' 
    }
  },
  ar: {
    translation: {
      'nav1': 'رئيسي',
      'nav2': 'أعمالي',
      'nav3': 'تواصل',
      'title1': 'هلا، أنا أسمي مصطفى عبدالشكور',
      'title2': 'انا مبرمج مواقع',
      'title3': 'صفحة المشاريع',
      'projects1': 'أعمالي',
      'projects2': 'هذه بعض من أعمالي السابقة',
      'projects3': 'تصفح المشروع',
      'project1': 'ملخص',
      'project2': 'عرض الموقع',
      'project3': 'عرض الكود',
      'projectDetails1': 'متعّقب كوفيد-19',
      'projectDetails2': 'تطبيق  يعرض أحدث البيانات الخاصة بكوفيد-19 مع عدد الحالات وعمليات التعافي والوفيات لأي بلد',
      'projectDetails3':'تطبيق دردشة',
      'projectDetails4':'تطبيق دردشة يستخدم للدردشة مع الناس بخصوصية أو في ,  مجموعات',
      'projectDetails5':'تطبيق الطقس',
      'projectDetails6':'تطبيق طقس يستخدم لعرض حالة الطقس في أي مدينة والتنبؤ به',
      'projectDetails7':'موقع لبيع النظارات',
      'projectDetails8':'موقع تجريبي متخصص في بيع النظارات المتنوعة',
      'contact1':'تم أرسال الرسالة. سيتم الرد عليكم قريبا عبر الايميل',
      'projectDetails9':'المجتمع الدولي للطاقة الاستوائية',
      'projectDetails10':'موقع مصمم من أجل عميل',
      'contact2':'العودة للصفحة',
      'contact3':'هل لديك أي استفسار؟',
      'contact4':'الاسم',
      'contact5':'ايميل',
      'contact6':'الرسالة',
      'contact7':'أرسال',
      'text1a': 'موقع للتجارة الالكترونية',
      'text2a': 'موقع كامل لبيع سمعات الهاتف مع جميع الخواص الرئسية',
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;